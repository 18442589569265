import React from 'react';
import './Inbox.css';

const Inbox = () => {
  const notifications = [
    { id: 1, type: 'friend-request', message: 'Alice sent you a friend request.' },
    { id: 2, type: 'message', message: 'Bob sent you a message.' },
    { id: 3, type: 'achievement', message: 'You earned a new badge: Math Wizard!' },
    { id: 4, type: 'announcement', message: 'New features have been added to the app!' }
  ];

  return (
    <div className="inbox">
      <h1>Inbox</h1>
      <ul className="notifications-list">
        {notifications.map(notification => (
          <li key={notification.id} className={`notification ${notification.type}`}>
            {notification.message}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Inbox;
