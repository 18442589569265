import React, { useState } from 'react';
import './Settings.css';

const Settings = () => {
  const [schoolExtracurricular, setSchoolExtracurricular] = useState(50);
  const [subjects, setSubjects] = useState({
    English: 20,
    Math: 20,
    Science: 20,
    History: 20,
    SocialSciences: 20
  });
  const [notice, setNotice] = useState("");
  const [noticeClass, setNoticeClass] = useState("");

  const handleSliderInputChange = (subject, value) => {
    const currentValue = subjects[subject];
    const newSubjects = { ...subjects, [subject]: parseInt(value, 10) };
    const total = Object.values(newSubjects).reduce((acc, val) => acc + val, 0);

    if (total <= 100 || parseInt(value, 10) < currentValue) {
      setSubjects(newSubjects);
      setNotice("");
      setNoticeClass("");
    } else if (total > 100) {
      setNotice(`You can't allocate more than 100%. Please reduce allocation to other subjects.`);
      setNoticeClass("");
    }

    if (total === 100) {
      setNotice("You have allocated all your time perfectly.");
      setNoticeClass("green");
    }
  };

  const totalSubjectTime = Object.values(subjects).reduce((acc, val) => acc + val, 0);

  return (
    <div className="settings">
      <h1>Settings</h1>
      <div className="time-allocation">
        <div className="school-extracurricular">
          <div className="slider-container">
            <label>School vs Extracurricular: {schoolExtracurricular}% School / {100 - schoolExtracurricular}% Extracurricular</label>
            <input
              type="range"
              min="0"
              max="100"
              value={schoolExtracurricular}
              onChange={(e) => setSchoolExtracurricular(parseInt(e.target.value, 10))}
            />
          </div>
        </div>
        <div className="subject-sliders-container">
          <div className="subject-sliders">
            {Object.keys(subjects).map((subject) => (
              <div key={subject} className="slider-container">
                <label>{subject}: {subjects[subject]}%</label>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={subjects[subject]}
                  onChange={(e) => handleSliderInputChange(subject, e.target.value)}
                />
              </div>
            ))}
          </div>
          <div className="total-allocation">
            <div>Total: {totalSubjectTime}%</div>
            {totalSubjectTime < 100 && <div className={`notice ${noticeClass}`}>You still have time to allocate.</div>}
            {totalSubjectTime >= 100 && <div className={`notice ${noticeClass}`}>{notice}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
