// Header.js
import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import './Header.css';

const Header = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State for mobile menu
  const searchRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const menuItems = [
    { path: '/', icon: 'fa-home', label: 'Home' },
    { path: '/dashboard', icon: 'fa-grip-horizontal', label: 'Dashboard' },
    { path: '/social', icon: 'fa-user-friends', label: 'Social' },
    { path: '/discover', icon: 'fa-solid fa-radar', label: 'Discover' }
  ];

  const dropdownItems = [
    { path: '/inbox', icon: 'fa-inbox', label: 'Inbox' },
    { path: '/settings', icon: 'fa-cog', label: 'Settings' },
    { path: '/logout', icon: 'fa-sign-out-alt', label: 'Logout' }
  ];

  const footerLinks = [
    { path: '/terms', label: 'Terms' },
    { path: '/privacy', label: 'Privacy' }
  ];

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearchOpen(false);
      }
      if (!event.target.closest('.dropdown-menu') && !event.target.closest('.user-profile button')) {
        setIsDropdownOpen(false);
      }
      if (!event.target.closest('.mobile-dropdown-menu') && !event.target.closest('.mobile-menu-button')) {
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isActive = (path) => location.pathname === path;

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      const currentIndex = menuItems.findIndex(item => item.path === location.pathname);
      const nextIndex = (currentIndex + 1) % menuItems.length;
      navigate(menuItems[nextIndex].path);
    },
    onSwipedRight: () => {
      const currentIndex = menuItems.findIndex(item => item.path === location.pathname);
      const prevIndex = (currentIndex - 1 + menuItems.length) % menuItems.length;
      navigate(menuItems[prevIndex].path);
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <header className="header" {...handlers}>
      <div className="logo">PAL</div>
      <nav>
        {menuItems.map((item, index) => (
          <Link key={index} to={item.path} className={isActive(item.path) ? 'active' : ''}>
            <i className={`fas ${item.icon}`}></i>
            <span>{item.label}</span>
          </Link>
        ))}
        <div className="search-container" ref={searchRef}>
          <button onClick={toggleSearch}>
            <i className="fas fa-search"></i>
          </button>
          {isSearchOpen && (
            <input type="text" placeholder="Search..." className="search-input" />
          )}
        </div>
      </nav>
      <button className="mobile-menu-button" onClick={toggleMobileMenu}>
        <i className="fas fa-bars"></i>
      </button>
      <div className={`mobile-dropdown-menu ${isMobileMenuOpen ? 'show' : ''}`}>
        {menuItems.map((item, index) => (
          <Link key={index} to={item.path} onClick={() => setIsMobileMenuOpen(false)}>
            <i className={`fas ${item.icon}`}></i>
            <span>{item.label}</span>
          </Link>
        ))}
      </div>
      <div className="user-profile">
        <div className="total-xp">1000 XP</div>
        <div className="profile-picture"></div>
        <div className="user-info">
          <span className="username">Leopold</span>
        </div>
        <button onClick={toggleDropdown}>
          <i className="fas fa-chevron-down"></i>
        </button>
        <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
          {dropdownItems.map((item, index) => (
            <Link key={index} to={item.path}>
              <i className={`fas ${item.icon}`}></i>
              {item.label}
            </Link>
          ))}
          <div className="dropdown-footer">
            {footerLinks.map((link, index) => (
              <Link key={index} to={link.path}>
                {link.label}
              </Link>
            ))}
            <div>© 2024 PAL</div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
