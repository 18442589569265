// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import Home from './Home';
import Dashboard from './Dashboard';
import Social from './Social';
import Discover from './Discover';
import Inbox from './Inbox';
import Settings from './Settings'; // Import the new Settings component

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/social" element={<Social />} />
        <Route path="/discover" element={<Discover />} />
        <Route path="/inbox" element={<Inbox />} />
        <Route path="/settings" element={<Settings />} /> {/* Add Settings route */}
      </Routes>
    </Router>
  );
};

export default App;
