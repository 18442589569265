import React, { useState, useEffect } from 'react';
import './Discover.css';

const topics = [
  'Neuroscience', 'Artificial Intelligence', 'Cognitive Psychology', 'Machine Learning', 
  'Brain-Computer Interfaces', 'Neural Networks', 'Robotics', 'Genetics', 
  'Quantum Computing', 'Bioinformatics', 'Neuron', 'Synapse', 'Dendrite', 
  'Charles Darwin', 'Albert Einstein', 'Isaac Newton', 'Marie Curie', 
  'Nikola Tesla', 'Alan Turing', 'Stephen Hawking', 'Watson and Crick', 
  'Pavlov\'s Dog', 'DNA', 'RNA', 'CRISPR', 'Neuroplasticity', 
  'Deep Learning', 'Reinforcement Learning', 'Supervised Learning', 
  'Unsupervised Learning', 'Support Vector Machine', 'Convolutional Neural Networks', 
  'Recurrent Neural Networks', 'Natural Language Processing', 'Computer Vision', 
  'Big Data', 'Data Mining', 'Data Science', 'Biostatistics', 
  'Genomics', 'Proteomics', 'Metabolomics', 'Epigenetics', 
  'Single-cell RNA sequencing', 'Metagenomics', 'Gene Editing', 
  'Stem Cells', 'Immunotherapy', 'Cancer Biology', 'Pathogenesis', 
  'Epidemiology', 'Virology', 'Bacteriology', 'Mycology', 
  'Parasitology', 'Bioethics', 'Biophysics', 'Biochemistry', 
  'Molecular Biology', 'Cell Biology', 'Developmental Biology', 
  'Evolutionary Biology', 'Ecology', 'Marine Biology', 'Astrobiology', 
  'Space Exploration', 'Astrophysics', 'Cosmology', 'Planetary Science', 
  'Exoplanets', 'Galaxies', 'Black Holes', 'Relativity', 
  'Quantum Mechanics', 'String Theory', 'Multiverse Theory', 
  'Dark Matter', 'Dark Energy', 'Particle Physics', 
  'Standard Model', 'Higgs Boson', 'Large Hadron Collider', 
  'Fermions', 'Bosons', 'Quarks', 'Leptons', 'Gluons', 
  'Gravitons', 'Photonics', 'Optics', 'Laser Technology', 
  'Nanotechnology', 'Materials Science', 'Quantum Computing', 
  'Cryptography', 'Information Theory', 'Algorithms', 'Turing Machine'
];

const getRandomTopics = (topics, count) => {
  const shuffled = topics.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};

const Discover = () => {
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [displayedTopics, setDisplayedTopics] = useState([]);

  useEffect(() => {
    setDisplayedTopics(getRandomTopics(topics, 20));
  }, []);

  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
  };

  return (
    <div className="discover">
      <div className="learn-section">
        <h1>Learn</h1>
      </div>
      <div className="topics-section">
        {displayedTopics.map((topic, index) => (
          <div
            key={index}
            className={`topic-item ${selectedTopic === topic ? 'selected' : ''}`}
            onClick={() => handleTopicClick(topic)}
          >
            {topic}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Discover;
