import React, { useState } from 'react';
import './Dashboard.css';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const [bookmarkedContent] = useState([
    'Neuroscience Basics',
    'Introduction to AI',
    'Quantum Mechanics Overview'
  ]);

  const [tests] = useState([
    'Midterm Exam - Biology',
    'Final Exam - Mathematics',
    'Practice Test - Physics'
  ]);

  const [interestedTopics] = useState([
    'Machine Learning',
    'Genetics',
    'Robotics'
  ]);

  const xpData = {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7', 'Week 8'],
    datasets: [
      {
        label: 'XP Points',
        data: [120, 150, 180, 200, 220, 240, 260, 280],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
    ],
  };

  const timeSpentData = {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7', 'Week 8'],
    datasets: [
      {
        label: 'Hours Spent',
        data: [10, 12, 15, 20, 22, 18, 25, 30],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
      },
    ],
  };

  const subjectPerformanceData = {
    labels: ['Physics', 'Mathematics', 'Biology', 'Chemistry'],
    datasets: [
      {
        label: 'Performance Score',
        data: [80, 85, 90, 75],
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        fill: true,
      },
    ],
  };

  return (
    <div className="dashboard">
      <h1>Dashboard</h1>
      <div className="dashboard-content">
        <div className="left-section">
          <div className="section">
            <h2>Bookmarked Content</h2>
            <ul>
              {bookmarkedContent.map((content, index) => (
                <li key={index}>{content}</li>
              ))}
            </ul>
          </div>
          <div className="section">
            <h2>Tests Preparing</h2>
            <ul>
              {tests.map((test, index) => (
                <li key={index}>{test}</li>
              ))}
            </ul>
          </div>
          <div className="section">
            <h2>Topics Interested In</h2>
            <ul>
              {interestedTopics.map((topic, index) => (
                <li key={index}>{topic}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="right-section">
          <div className="chart-section">
            <h2>XP Points Gained Each Week</h2>
            <div className="chart">
              <Bar data={xpData} options={{ responsive: true, maintainAspectRatio: false }} />
            </div>
          </div>
          <div className="chart-section">
            <h2>Time Spent Each Week</h2>
            <div className="chart">
              <Line data={timeSpentData} options={{ responsive: true, maintainAspectRatio: false }} />
            </div>
          </div>
          <div className="chart-section">
            <h2>Subject Performance</h2>
            <div className="chart">
              <Line data={subjectPerformanceData} options={{ responsive: true, maintainAspectRatio: false }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
