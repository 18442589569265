import React, { useState, useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import './Home.css';

// Function to shuffle the questions array
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const Home = () => {
  const [questions, setQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showTips, setShowTips] = useState(false);
  const [showSolution, setShowSolution] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showShareOptions, setShowShareOptions] = useState(false);

  const tipsRef = useRef(null);
  const solutionRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    fetch('/questions.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch questions');
        }
        return response.json();
      })
      .then(data => {
        const shuffledQuestions = shuffleArray(data); // Shuffle the questions
        setQuestions(shuffledQuestions);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching questions:', error);
        setError(error.message);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tipsRef.current && !tipsRef.current.contains(event.target)) {
        setShowTips(false);
      }
      if (solutionRef.current && !solutionRef.current.contains(event.target)) {
        setShowSolution(false);
      }
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const currentContent = questions[currentIndex];

  const nextContent = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % questions.length);
    resetState();
  };

  const prevContent = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + questions.length) % questions.length);
    resetState();
  };

  const resetState = () => {
    setShowTips(false);
    setShowSolution(false);
    setShowMenu(false);
    setSelectedAnswer(null);
    setShowShareOptions(false);
  };

  const toggleTips = () => {
    setShowTips(!showTips);
    setShowSolution(false);
  };

  const toggleSolution = () => {
    setShowSolution(!showSolution);
    setShowTips(false);
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const toggleShareOptions = () => {
    setShowShareOptions(!showShareOptions);
  };

  const handleAnswerClick = (index) => {
    setSelectedAnswer(index);
  };

  const handleSubmitAnswer = () => {
    // Handle the answer submission logic here
    alert(`Answer submitted: ${selectedAnswer !== null ? questions[currentIndex].options[selectedAnswer] : 'No answer selected'}`);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => nextContent(),
    onSwipedRight: () => prevContent(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (questions.length === 0) return <div>No questions available.</div>;

  return (
    <div className="home">
      <button className="nav-button left" onClick={prevContent}>
        <i className="fas fa-chevron-left"></i>
      </button>
      <div className="content-area" {...handlers}>
        <div className="top-bar">
          <div className="tags">
            <span className="tag subject">{currentContent.subject}</span>
            {currentContent.tags.map((tag, index) => (
              <span key={index} className="tag">{tag}</span>
            ))}
          </div>
          <div className="menu-container">
            <button className="menu-button" onClick={toggleMenu}>
              <i className="fas fa-ellipsis-v"></i>
            </button>
            {showMenu && (
              <div className="menu-options" ref={menuRef}>
                <button>Too Easy</button>
                <button>Too Difficult</button>
                <button>Not Interested</button>
                <button>Report Error</button>
                <button>Feedback</button>
              </div>
            )}
          </div>
        </div>
        <div className="main-content">
          <h2>{currentContent.question}</h2>
          {currentContent.type === 'multiple-choice' && (
            <ul className="answer-options">
              {currentContent.options.map((option, index) => (
                <li 
                  key={index} 
                  className={`answer-option ${selectedAnswer === index ? 'selected' : ''}`}
                  onClick={() => handleAnswerClick(index)}
                >
                  {option}
                </li>
              ))}
            </ul>
          )}
          {currentContent.type === 'true-false' && (
            <div className="true-false-options">
              <button 
                className={`answer-option ${selectedAnswer === 0 ? 'selected' : ''}`}
                onClick={() => handleAnswerClick(0)}
              >
                True
              </button>
              <button 
                className={`answer-option ${selectedAnswer === 1 ? 'selected' : ''}`}
                onClick={() => handleAnswerClick(1)}
              >
                False
              </button>
            </div>
          )}
          {currentContent.type === 'instructional' && <p>{currentContent.content}</p>}
        </div>
        {currentContent.type !== 'instructional' && (
          <div className="submit-button-container">
            <button className="submit-button" onClick={handleSubmitAnswer}>
              Confirm
            </button>
          </div>
        )}
        <div className="bottom-bar">
          <div className="action-buttons">
            <button><i className="far fa-bookmark"></i></button>
            <button onClick={toggleShareOptions}><i className="fas fa-share"></i></button>
            <button><i className="far fa-comment"></i></button>
            <button><i className="far fa-thumbs-up"></i></button>
            <button><i className="far fa-thumbs-down"></i></button>
          </div>
          {currentContent.type !== 'instructional' && (
            <div className="help-buttons">
              <button onClick={toggleTips}><i className="fas fa-lightbulb"></i> Tips</button>
              <button onClick={toggleSolution}><i className="fas fa-hexagon-check"></i> Solution</button>
            </div>
          )}
        </div>
      </div>
      <button className="nav-button right" onClick={nextContent}>
        <i className="fas fa-chevron-right"></i>
      </button>
      {showTips && (
        <div className="overlay">
          <div className="overlay-content" ref={tipsRef}>
            <h3>Tips</h3>
            <p>{currentContent.tips}</p>
            <button onClick={toggleTips}>Close</button>
          </div>
        </div>
      )}
      {showSolution && (
        <div className="overlay">
          <div className="overlay-content" ref={solutionRef}>
            <h3>Solution</h3>
            <p>{currentContent.solution}</p>
            <button onClick={toggleSolution}>Close</button>
          </div>
        </div>
      )}
      {showShareOptions && (
        <div className="share-options" ref={menuRef}>
          <button><i className="fab fa-twitter"></i> X</button>
          <button><i className="fab fa-facebook"></i> Facebook</button>
          <button><i className="fab fa-instagram"></i> Instagram</button>
          <button><i className="fas fa-sms"></i> Text Message</button>
          <button><i className="fab fa-whatsapp"></i> WhatsApp</button>
          <button><i className="fab fa-linkedin"></i> LinkedIn</button>
          <button><i className="fab fa-reddit"></i> Reddit</button>
        </div>
      )}
    </div>
  );
};

export default Home;
