import React, { useState } from 'react';
import './Social.css';

const Social = () => {
  const friends = ['Alice', 'Bob', 'Charlie', 'David', 'Eve'];
  const rankings = [
    { name: 'Anna', xp: 1500 },
    { name: 'John', xp: 1400 },
    { name: 'Leopold', xp: 1300 },
    { name: 'Kate', xp: 1200 },
    { name: 'Max', xp: 1100 },
    { name: 'Olivia', xp: 1000 }
  ];

  const [selectedFriend, setSelectedFriend] = useState(null);
  const [rankingFilter, setRankingFilter] = useState('all');
  const [selectedGame, setSelectedGame] = useState(null);

  const dummyChatHistory = {
    Alice: [
      { user: 'Alice', message: 'Hello!' },
      { user: 'You', message: 'Hi Alice, how are you?' },
      { user: 'Alice', message: 'I am good, thanks!' }
    ],
    Bob: [
      { user: 'Bob', message: 'Hey!' },
      { user: 'You', message: 'What’s up Bob?' },
      { user: 'Bob', message: 'Not much, just chilling.' }
    ],
    Charlie: [
      { user: 'Charlie', message: 'Hi there!' },
      { user: 'You', message: 'Hello Charlie!' },
      { user: 'Charlie', message: 'How’s your day going?' }
    ],
    David: [
      { user: 'David', message: 'Good morning!' },
      { user: 'You', message: 'Morning David!' },
      { user: 'David', message: 'Any plans for today?' }
    ],
    Eve: [
      { user: 'Eve', message: 'Hi!' },
      { user: 'You', message: 'Hey Eve!' },
      { user: 'Eve', message: 'How is everything?' }
    ]
  };

  const handleFriendClick = (friend) => {
    setSelectedFriend(friend);
  };

  const handleFilterChange = (e) => {
    setRankingFilter(e.target.value);
  };

  const handleGameSelect = (game) => {
    setSelectedGame(game);
  };

  const filteredRankings = rankings.filter(rank => {
    if (rankingFilter === 'all') return true;
    // Add your filter logic here based on rankingFilter
    return true;
  });

  const handleCloseChat = () => {
    setSelectedFriend(null);
  };

  return (
    <div className="social">
      <div className="column friend-list">
        <h2><i className="fas fa-user-group"></i> Friends</h2>
        <ul>
          {friends.map((friend, index) => (
            <li key={index} onClick={() => handleFriendClick(friend)}>
              <div className="friend-item">
                <i className="fas fa-face-sunglasses"></i>
                {friend}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="column ranking">
        <h2><i className="fas fa-ranking-star"></i> Ranking</h2>
        <div className="ranking-filters">
          <label>
            Filter:
            <select value={rankingFilter} onChange={handleFilterChange}>
              <option value="all">All</option>
              <option value="country">Country</option>
              <option value="friends">Friends Only</option>
              <option value="school">My School Only</option>
              <option value="subjects">Certain Subjects</option>
            </select>
          </label>
        </div>
        <ul>
          {filteredRankings.map((rank, index) => (
            <li key={index} className={rank.name === 'Leopold' ? 'my-ranking' : ''}>
              <div className="ranking-item">
                <span className="circle"></span>
                {rank.name}: {rank.xp} XP
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="column game-section">
        <h2><i className="fas fa-gamepad"></i> Games</h2>
        <ul>
          <li onClick={() => handleGameSelect('Random Quiz')}>
            <div className="game-item">
              <i className="far fa-gamepad"></i>
              Compete in a Random Quiz
            </div>
          </li>
          <li onClick={() => handleGameSelect('Math Duel')}>
            <div className="game-item">
              <i className="far fa-gamepad"></i>
              Math Duel
            </div>
          </li>
          <li onClick={() => handleGameSelect('Memory Challenge')}>
            <div className="game-item">
              <i className="far fa-gamepad"></i>
              Memory Challenge
            </div>
          </li>
        </ul>
        {selectedGame && <div className="selected-game">Selected Game: {selectedGame}</div>}
      </div>

      {selectedFriend && (
        <div className="chat-overlay" onClick={handleCloseChat}>
          <div className="chat-popup" onClick={(e) => e.stopPropagation()}>
            <div className="chat-header">
              <h3>Chatting with: {selectedFriend}</h3>
              <span className="chat-close" onClick={handleCloseChat}>&times;</span>
            </div>
            <div className="chat-history">
              {dummyChatHistory[selectedFriend].map((msg, index) => (
                <div key={index} className={`chat-message ${msg.user === 'You' ? 'user' : 'other'}`}>
                  <p>{msg.message}</p>
                </div>
              ))}
            </div>
            <div className="chat-footer">
              <input type="text" className="chat-input" placeholder="Type a message..." />
              <div className="chat-buttons">
                <button><i className="fas fa-paperclip"></i></button>
                <button><i className="fas fa-paper-plane"></i></button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Social;
